import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_breadcrumb = _resolveComponent("header-breadcrumb")!
  const _component_header_info = _resolveComponent("header-info")!
  const _component_el_header = _resolveComponent("el-header")!

  return (_openBlock(), _createBlock(_component_el_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_header_breadcrumb),
      _createVNode(_component_header_info)
    ]),
    _: 1
  }))
}