
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  name: "Header",
  components: {
    HeaderBreadcrumb: defineAsyncComponent(
      () => import("./includes/HeaderBreadcrumb.vue")
    ),
    HeaderInfo: defineAsyncComponent(() => import("./includes/HeaderInfo.vue"))
  }
});
